/* CSS for mobile devices */
@media (max-width: 767px) {
    .carousel-text {
        font-size: 3em;
        width: 100%;                      
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        color: white;
        text-shadow: -1px 0 #008dd2, 0 1px #008dd2, 1px 0 #008dd2, 0 -1px #008dd2;
        text-align: center;
    }
}

/* CSS for desktop devices */
@media (min-width: 768px) {
    .carousel-text {
        font-size: 5em;
        width: 100%;                      
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        color: white;
        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        text-align: center;
    }
}

.responsive-image {
    width: 100%;
    background-size: 100% 100%;
}

.satisfy-regular {
    font-family: "Satisfy", cursive;
    font-weight: 400;
    font-style: normal;
}

@keyframes fade-in-out {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fade-in-out {
    animation: fade-in-out 5s linear infinite;
}


