.themeColor {
    color: #008dd2
}


.aboutUs-desc {
    /* Desktop version */
    padding-left: 15%;
    padding-right: 15%;
    margin-bottom: 23em;
    /* Mobile version */
    @media (max-width: 768px) {
        padding-left: 10%;
        padding-right: 10%;
        margin-bottom: 5em;
    }
}