.themeColor {
    color: #008dd2
}


.responsiveStyling {
    /* Desktop version */
    padding-left: 30%;
    padding-right: 30%;

    /* Mobile version */
    @media (max-width: 768px) {
        padding-left: 10%;
        padding-right: 10%;
    }
}