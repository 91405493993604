.themeColor {
    color: #008dd2
}


.responsiveStyling {
    /* Desktop version */
    padding-left: 25%;
    padding-right: 25%;

    /* Mobile version */
    @media (max-width: 768px) {
        padding-left: 10%;
        padding-right: 10%;
    }
}


.services-text-main {
    /* Desktop version */
    font-size: 5em;
    width: 100%;
    position: relative;
    top: 10%;
    left: 15%;
    z-index: 1;
    color: white;
    text-shadow: -1px 0 #008dd2, 0 1px #008dd2, 1px 0 #008dd2, 0 -1px #008dd2;
    text-align: center;

    /* Mobile version */
    @media (max-width: 768px) {
        font-size: 2em;
        top: 20%;
        left: 10%;
    }
}

.services-text-desc {
    /* Desktop version */
    font-size: 1.3em;
    width: 25%;
    position: relative;
    top: 35%;
    left: 25%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: white;
    text-shadow: -1px 0 #008dd2, 0 1px #008dd2, 1px 0 #008dd2, 0 -1px #008dd2;
    text-align: center;

    /* Mobile version */
    @media (max-width: 768px) {
        font-size: 0.5em;
        top: 30%;
        left: 27.5%;
        width: 50%;
    }
}

.bg-cleaning {
    background-image: url('../SlideShow/cleaning.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 46em;
    
    /* Mobile version */
    @media (max-width: 768px) {
        height: 15em;
    }
}

.extraMargin{


    /* Mobile version */
    @media (max-width: 768px) {
        height: 39em;
        margin-bottom: 5em;
    }
}